import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import SanityImage from 'gatsby-plugin-sanity-image-alt'
import loadable from '@loadable/component'

import { useSanityImageShape } from '../../helpers/useSanityImageShape'

const PortableText = loadable(() => import('../PortableText'))
const ReviewDisplay = loadable(() => import('../ReviewDisplay'))

export const Card = ({ content, cardClasses, hosts, popular, spaces, openInNew, roomNumber }) => {
  const language = process.env.GATSBY_SITE_LANGUAGE
  let link = content.slug
  if (link) {
    if (!content.slug.endsWith('/')) {
      link = `${content.slug}/`
    }
  }

  const featuredImageShaped = useSanityImageShape(content.featuredImage)  

  if (openInNew === true) {
    return (
      <a
        href={link}
        target={openInNew === true ? '_blank' : ''}
        className={`bg-white rounded-lg overflow-hidden shadow-sm transition-shadow duration-300 transform hover:shadow-md ${cardClasses}`}
      >
        <div className="relative bg-black pointer-events-none h-card-sm md:h-card-md">
          {popular && !spaces && (
            <span className="absolute px-4 py-2 text-xs leading-none text-white bg-blue-500 rounded-full top-4 right-4 bg-opacity-8 z-1">
              <span className="block translate-y-px">{language == 'en' ? 'Popular' : 'Benvoluto'}</span>
            </span>
          )}

          {spaces && (
            <span className="absolute px-4 py-2 text-xs leading-none text-white bg-red-400 rounded-full top-4 right-4 bg-opacity-8 z-1">
              <span className="block translate-y-px">
                {language == 'en' ? `Only ${spaces} spot${spaces === 1 ? '' : 's'} left!` : `${spaces === 1 ? 'Rimane solo 1 posto' : `Rimasti solo ${spaces} posti`}`}
              </span>
            </span>
          )}
          {featuredImageShaped && (
            <SanityImage
              {...featuredImageShaped}
              className="object-cover w-full h-full opacity-75"
              alt={content.featuredImage.alt}
              width={520}
              height={300}
            />
          )}
          <div className="absolute inset-0 flex flex-col justify-end p-18px">
            {/* <div className="absolute flex pb-18px lg:pb-8">

            {hosts && hosts.map(host => {
              
              let personShaped = null
              personShaped = useSanityImageShape(host.image)
              
              return <div className="-mr-6 ">
              <SanityImage
                {...personShaped}
                className="object-cover mb-4 border-white border-solid rounded-full w-avatar-sm h-avatar-sm lg:w-avatar-lg lg:h-avatar-lg lg:mb-auto z-1 shadow-avatar border-px"
                alt={host?.alt}
                width={520}
                height={300}
              />
              </div>
              
            })}

          </div> */}

            {roomNumber &&
              <p className="absolute text-base font-bold text-white top-4 left-4">Room {roomNumber}</p>
            }
            
            <h3 className="font-bold text-white text-17 md:text-18">
              {content.title}
            </h3>
            <ReviewDisplay
              reviewId={content.id}
              byType="Current Page"
              textClasses="text-white text-sm"
            />
          </div>
        </div>
        <div className="p-4 pointer-events-none">
          {content.features?.length > 0 && (
            <p className="flex mb-4 font-bold text-12 md:text-14">
              {content.features.map((feature, index) => (
                <span key={index}>
                  <span>{feature}</span>

                  {index !== content.features.length - 1 && (
                    <span className="inline-block mx-2">&bull;</span>
                  )}
                </span>
              ))}
            </p>
          )}
          <div className="h-12 overflow-hidden break-normal truncate-last-line md:h-55px">
            <PortableText blocks={content.description} isCard />
          </div>
        </div>
      </a>
    )
  }

  return (
    <Link
      to={link}
      target={openInNew === true ? '_blank': ''}
      className={`bg-white rounded-lg overflow-hidden shadow-sm transition-shadow duration-300 transform hover:shadow-md ${cardClasses}`}
    >
      <div className="relative bg-black pointer-events-none h-card-sm md:h-card-md">
        {popular && !spaces && (
          <span className="absolute px-4 py-2 text-xs leading-none text-white bg-blue-500 rounded-full top-4 right-4 bg-opacity-8 z-1">
            <span className="block translate-y-px">{language == 'en' ? 'Popular' : 'Benvoluto'}</span>
          </span>
        )}

        {spaces && (
          <span className="absolute px-4 py-2 text-xs leading-none text-white bg-red-400 rounded-full top-4 right-4 bg-opacity-8 z-1">
            <span className="block translate-y-px">
            {language == 'en' ? `Only ${spaces} spot${spaces===1?'':'s'} left!` : `${spaces===1?'Rimane solo 1 posto':`Rimasti solo ${spaces} posti`}`}
            </span>
          </span>
        )}
        {featuredImageShaped && (
          <SanityImage
            {...featuredImageShaped}
            className="object-cover w-full h-full opacity-75"
            alt={content.featuredImage.alt}
            width={520}
            height={300}
          />
        )}
        <div className="absolute inset-0 flex flex-col justify-end p-18px">
          {/* <div className="absolute flex pb-18px lg:pb-8">

            {hosts && hosts.map(host => {
              
              let personShaped = null
              personShaped = useSanityImageShape(host.image)
              
              return <div className="-mr-6 ">
              <SanityImage
                {...personShaped}
                className="object-cover mb-4 border-white border-solid rounded-full w-avatar-sm h-avatar-sm lg:w-avatar-lg lg:h-avatar-lg lg:mb-auto z-1 shadow-avatar border-px"
                alt={host?.alt}
                width={520}
                height={300}
              />
              </div>
              
            })}

          </div> */}

          <h3 className="font-bold text-white text-17 md:text-18">
            {content.title}
          </h3>
          <ReviewDisplay
            reviewId={content.id}
            byType="Current Page"
            textClasses="text-white text-sm"
          />
        </div>
      </div>
      <div className="p-4 pointer-events-none">
        {content.features?.length > 0 && (
          <p className="flex mb-4 font-bold text-12 md:text-14">
            {content.features.map((feature, index) => (
              <span key={index}>
                <span>{feature}</span>

                {index !== content.features.length - 1 && (
                  <span className="inline-block mx-2">&bull;</span>
                )}
              </span>
            ))}
          </p>
        )}
        <div className="h-12 overflow-hidden break-normal truncate-last-line md:h-55px">
          <PortableText blocks={content.description} isCard />
        </div>
      </div>
    </Link>
  )
}

Card.propTypes = {
  content: PropTypes.object,
  cardClasses: PropTypes.string,
  person: PropTypes.object,
  popular: PropTypes.bool,
}

Card.defaultProps = {
  content: {},
  cardClasses: '',
  person: null,
  popular: false,
}
