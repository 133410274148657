import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import { useRetreatQuery } from '../hooks/retreats'
import { Card } from './Card'

const Button = loadable(() => import('./Button'))

const PageBuilderRetreats = ({ block }) => {
  const data = useRetreatQuery()

  const language = process.env.GATSBY_SITE_LANGUAGE

  const { showAll, title, displayTitle, topLine } = block
  const [showMore, setShowMore] = useState(false)

  let retreats = data.allSanityRetreat.nodes


  if (block.specific?.length > 0) {
    retreats = block.specific
  }

  const cardClasses = 'col-span-8 md:col-span-4'

  let retreatOutput = retreats

  if (!block.showAll && !showMore && retreats.length > 2) {
    retreatOutput = retreats.slice(0,2)
  }
  
  return (
    <section
      className={`container grid grid-cols-12 mb-8 md:mb-24 ${
        topLine ? 'mt-0' : 'mt-8 md:mt-12'
      }`}
    >
      {topLine && (
        <hr className="col-span-12 mt-4 mb-12 border-gray-400 xl:col-span-10 xl:col-start-2 md:mb-20" />
      )}

      {displayTitle && title && (
        <h2 className="col-span-12 mb-8 text-lg font-bold text-gray-800 xl:col-span-10 xl:col-start-2 lg:mb-12">
          {title}
        </h2>
      )}
      <div className="grid grid-cols-8 col-span-12 gap-8 xl:col-span-10 xl:col-start-2 xl:gap-12">
        {retreatOutput.map((retreat, index) => {
          const featureImage =
            (retreat.bgImg?.asset && retreat.bgImg) ||
            retreat.gallery?.images.find(image => image.featured)

          const spaces = null
            // retreat.dates?.length > 1 ? null : retreat?.dates[0]?.spaces
        
          

          const content = {
            id: retreat._id,
            title: retreat.title,
            slug: `/${language === 'en' ? 'retreats' : 'ritiri'}/${retreat.slug.current}/`,
            features: retreat.detailsCard,
            featuredImage: featureImage,
            description: retreat.description || retreat._rawDescription,
          }

          return (
            <Card
              key={Date.now() + retreat._id}
              content={content}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              cardClasses={`${cardClasses}`}
              hosts={retreat.hosts}
              popular={retreat.popular}
              spaces={spaces}
            />
          )
        })}
      </div>
      {retreats.length > 2 && showAll !== true && (
        <div
          className={`col-span-12 xl:col-span-10 xl:col-start-2 pt-12 ${
            showMore ? 'hidden' : ''
          }`}
        >
          <Button
            colour="ghostBlueThick"
            showArrowIcon
            setShowMore={setShowMore}
          >
            {`Explore all ${retreats.length} retreats`}
          </Button>
        </div>
      )}
    </section>
  )
}

PageBuilderRetreats.propTypes = {
  block: PropTypes.object,
  title: PropTypes.string,
  showAll: PropTypes.bool,
  displayTitle: PropTypes.bool,
}

PageBuilderRetreats.defaultProps = {
  block: {},
  showAll: false,
  title: null,
  displayTitle: false,
}

export default PageBuilderRetreats
